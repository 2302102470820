<template>
  <ProfileField name="Цена за занятие" class="inputs-grid">
    <ProfilePriceInput
      v-model="form.mentor_price"
      :error="errors.mentor_price"
    />
  </ProfileField>
  <ProfileSkillsInput
    name="Скиллы"
    v-model="form.skills"
    :errors="errors"
  />
  <ProfileRequestsInput
    v-model="form.mentor_requests"
    :errors="errors"
  />
  <ProfileField v-if="!user.contact" name="Способ связи" class="inputs-grid">
    <ContactInput
      v-model:type="form.contact_type"
      v-model="form.contact"
      :error-type="errors.contact_type"
      :error="errors.contact"
    />
    <MentorContactNotice class="input-col-2" />
  </ProfileField>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeMentorInfo } from '@/api/user'
import cloneDeep from 'lodash.clonedeep'
import contactTypes from '~/data/contactTypes'

const emit = defineEmits(['close'])

const { isMobile } = useBreakpoints()

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  form,
  errors,
  submit,
  loading
} = useForm(storeMentorInfo, onSuccess)

async function onSuccess () {
  user.value.is_mentor = true
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    mentor_price: value?.mentor_price,
    skills: cloneDeep(value?.is_mentor ? (value.mentor_skills ?? value.skills) : value.specialist_skills) ?? [],
    mentor_requests: value?.mentor_requests?.join('\n') ?? [],
    contact_type: value?.contact_type ?? contactTypes[0].id,
    contact: value?.contact
  }
}, { immediate: true })
</script>

<style lang="scss">

</style>
