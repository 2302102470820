<template>
  <ProfileSection v-if="achievements" title="Достижения" :subtitle="subtitle">
    <div class="achievements">
      <HybridPopper
        v-for="achievement in achievements"
        :key="achievement.id"
        :hover="true"
        :interactive="false"
      >
        <div class="achievements-item" :class="{'achievements-item--done': achievement.is_done}">
          <img :src="achievement.icon.url" :alt="achievement.title">
        </div>
        <template #content>
          <div class="achievements-item__tooltip" :class="{'achievements-item__tooltip--done': achievement.is_done}">
            <div class="achievements-item__header">
              <div class="achievements-item__title">{{ achievement.title }}</div>
              <CoinIndicator class="achievements-item__coins" v-if="achievement.coins > 0" plain small :coins="achievement.coins" />
            </div>
            <div class="achievements-item__desc">{{ achievement.description }}</div>
            <div class="achievements-item__status">
              <IconLockOpen v-if="achievement.is_done" class="icon" />
              <IconLock v-else class="icon" />
              <span>{{ achievement.is_done ? 'Достижение получено' : 'Достижение не получено' }}</span>
            </div>
          </div>
        </template>
      </HybridPopper>
    </div>
  </ProfileSection>
</template>

<script setup>
import ProfileSection from '@/components/user/profile/ProfileSection'
import { IconLock, IconLockOpen } from '@tabler/icons-vue'
import CoinIndicator from '@/components/parts/CoinIndicator'
import { getUserAchievements } from '@/api/achievements'

const props = defineProps({
  user: Object,
  isMentor: Boolean
})

const user = inject('user')
const data = computed(() => props.user ?? user.value)

const { data: achievements } = await useAsyncData(() => getUserAchievements(
  data.value.user_id ?? data.value.id,
  Object.hasOwn(data.value, 'is_mentor') ? data.value.is_mentor : !!props.isMentor
))

const subtitle = computed(() => {
  if (achievements.value?.some(a => a.is_done)) return null
  if (data.value.id === user.value?.id) return 'Вы пока что не заработали достижения'
  return `${data.value.first_name} еще не заработал достижений`
})
</script>

<style lang="scss">
.achievements {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.achievements-item {
  position: relative;
  width: 81px;
  height: 81px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: .5;
    transition: .2s opacity;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-weight: 700;
    color: var(--color-elements-primary);
  }
  &__status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    .icon {
      color: var(--color-elements-tertiary);
      width: 16px;
      height: 16px;
    }
  }
  &__tooltip {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &--done .achievements-item__status .icon {
      color: var(--color-primary);
    }
  }

  &:hover > img, &--done > img {
    opacity: 1;
  }
}
</style>
