<template>
  <ProfileField name="Почасовая ставка">
    <AtomInput
      name="Почасовая ставка"
      symbol="₽"
      v-model="form.specialist_price"
      :error="errors.specialist_price"
      :process-field="processField"
    />
  </ProfileField>
  <ProfileSkillsInput
    name="Скиллы"
    v-model="form.skills"
    :errors="errors"
  />
  <ProfileRequestsInput
    v-model="form.specialist_requests"
    :errors="errors"
  />
  <ProfileContactsInput
    v-if="!contactsHidden"
    v-model="form.contacts"
    :errors="errors"
  />
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeSpecialistInfo } from '@/api/user'
import cloneDeep from 'lodash.clonedeep'

const emit = defineEmits(['close'])

const { isMobile } = useBreakpoints()

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  form,
  errors,
  submit,
  loading
} = useForm(storeSpecialistInfo, onSuccess)

async function onSuccess () {
  user.value.is_specialist = true
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    specialist_price: value?.specialist_price,
    skills: cloneDeep(value?.is_specialist ? value.specialist_skills : (value.mentor_skills ?? value.skills)) ?? [],
    specialist_requests: value?.specialist_requests?.join('\n') ?? [],
    contacts: value?.contacts ?? {}
  }
}, { immediate: true })

const processField = value => value.toString().replace(/\D/g, '')

const contactsHidden = computed(() => typeof user.value.contacts === 'object' && user.value.contacts !== null && Object.keys(user.value.contacts).length)
</script>

<style lang="scss">

</style>
