<template>
  <ProfileField name="Учебное заведение">
    <ProfileFieldBody
      :accent="item.organization"
      :additional="dates"
      :description="item.direction"
    />
  </ProfileField>
</template>

<script setup>
import ProfileField from '@/components/user/profile/ProfileField'
import ProfileFieldBody from '@/components/user/profile/ProfileFieldBody'

const props = defineProps({
  item: Object
})

const dates = computed(() => props.item.year_to ? `Окончил в ${props.item.year_to} г.` : 'Обучается в настоящее время')
</script>

<style scoped lang="scss">

</style>
