<template>
  <ProfileEducationInput
    v-model="form.educations"
    :errors="errors"
  />
</template>

<script setup>
import useForm from '~/composables/useForm'
import cloneDeep from 'lodash.clonedeep'
import { storeEducationInfo } from '@/api/user'
import ProfileEducationInput from '@/components/user/profile/inputs/ProfileEducationInput'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')
const {
  form,
  errors,
  submit,
  loading
} = useForm(storeEducationInfo, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    educations: cloneDeep(value?.educations) ?? []
  }
  form.value.educations.forEach(e => e.year_to = e.year_to?.toString())
}, { immediate: true })
</script>

<style scoped lang="scss">

</style>
