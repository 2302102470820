<template>
  <div v-if="editable" ref="container" class="profile-toggle-section" :class="{ 'profile-toggle-section--expanded': toggle }">
    <div class="profile-toggle-section__header" @click="expand">
      <Switcher v-model="toggle" :not-clickable="!canToggleOff" @click.stop />
      <div class="profile-toggle-section__heading">
        <h2 class="profile-toggle-section__title">{{ title }}</h2>
        <div class="profile-toggle-section__desc">{{ description }}</div>
      </div>
      <EditButton v-if="toggle && (isMobile || !editing)" @click="edit" />
    </div>
    <div v-if="toggle" class="profile-toggle-section__body">
      <slot :readonly="false" />
    </div>
  </div>
  <slot v-else :readonly="true" />
</template>

<script setup>
const props = defineProps({
  title: String,
  description: String,
  editable: Boolean,
  editing: Boolean,
  toggle: Boolean,
  canToggleOff: Boolean,
  hashTrigger: String
})
const emit = defineEmits(['edit', 'update:toggle'])

const route = useRoute()
const { isMobile } = useBreakpoints()

const container = ref()

const toggle = computed({
  get: () => props.toggle,
  set: value => emit('update:toggle', value)
})
watch(toggle, v => v && nextTick(edit))
watch(() => props.editing, editing => !editing && props.canToggleOff && (toggle.value = false))

function checkHashTrigger () {
  if (
    props.editable
    && props.hashTrigger
    && !toggle.value
    && process.client
    && !isMobile.value
    && props.canToggleOff
    && route.hash === props.hashTrigger
  ) {
    toggle.value = true
    if (container.value) setTimeout(() => document.documentElement.scrollTop = container.value.offsetTop - 30)
  }
}
onMounted(checkHashTrigger)
watch(() => route.hash, checkHashTrigger)

const edit = (bool = true) => emit('edit', bool)
const expand = () => toggle.value = true
</script>

<style scoped lang="scss">
.profile-toggle-section {
  border-radius: 24px;
  border: 1px solid var(--color-elements-quantery);
  &:not(:first-child) {
    margin-top: 32px;
  }

  @media (max-width: 920px) {
    border: none;
  }

  &__header {
    padding: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: 920px) {
      padding: 0;
      align-items: flex-start;
    }
  }
  :deep(.switcher) {
    margin-right: 16px;

    @media (max-width: 920px) {
      margin-top: 4px;
    }
  }
  &__heading {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 4px 24px;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    color: var(--color-bg-dark);

    @media (max-width: 920px) {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-secondary);
  }
  &__body {
    padding: 36px;
    @media (max-width: 920px) {
      padding: 0;
    }
  }

  &--expanded {
    .profile-toggle-section {
      &__header {
        border-bottom: 1px solid var(--color-elements-quantery);
        cursor: auto;

        @media (max-width: 920px) {
          padding-bottom: 16px;
          border-color: var(--color-separator);
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>
