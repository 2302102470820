<template>
  <AtomInput v-if="editable && user.is_mentor" type="checkbox"
             v-model="form.mentor_visible" name="Отображать мой профиль в списке менторов" :error="errors.mentor_visible" />
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeMentorVisible } from '~/api/user'

const editable = inject('profile.editable')
const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  form,
  submit,
  errors
} = useForm(storeMentorVisible, () => updateUserInfo(), () => form.value.mentor_visible = false)

watch(user, value => {
  form.value = {
    mentor_visible: value?.mentor_visible ?? false
  }
}, { immediate: true })

watchDebounced(() => form.value.mentor_visible, value => {
  if (value !== user.value.mentor_visible) submit()
}, { debounce: 800 })
</script>

<style scoped lang="scss">

</style>
