<template>
  <ProfileField v-if="skills?.length" name="Скиллы" class="chip-list">
    <Chip not-clickable v-for="skill in skills" :key="skill.id" :title="skillName(skill)">{{ skillName(skill) }}</Chip>
  </ProfileField>
</template>

<script setup>
const props = defineProps({
  skills: Array
})

const skillName = s => (s.skill_name ?? s.name) + (s.level_name ? ` - ${s.level_name}` : '')
</script>

<style scoped lang="scss">

</style>
