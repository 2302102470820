<template>
  <ProfileField name="О себе" class="inputs-grid">
    <ProfileAboutInput
      v-if="isMobile"
      class="profile-main-form__desc input-col-2"
      v-model="form.about"
      :error="errors.about"
    />
    <AtomInput
      type="textarea"
      name="Подробное описание"
      class="input-col-2"
      v-model="form.general_about"
      :error="errors.general_about"
      desc="Опишите, что вы умеете делать, и чему можете научить."
    />
  </ProfileField>
  <ProfileLanguagesInput v-model="form.languages" />
  <ProfileContactsInput v-model="form.contacts" :errors="errors" />
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeGeneralInfo } from '@/api/user'

const emit = defineEmits(['close'])

const { isMobile } = useBreakpoints()

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  form,
  errors,
  submit,
  loading
} = useForm(storeGeneralInfo, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    about: value?.personal?.about,
    general_about: value?.general_about,
    languages: new Set(value?.languages?.map(l => l.id) ?? []),
    contacts: value?.contacts ?? {}
  }
}, { immediate: true })
</script>

<style lang="scss">

</style>
