<template>
  <ProfileSection ref="section" title="Работа и проекты" @submit="submit" :pending="loading" class="flex-mobile-separator">
    <template #default>
      <ProfileExperienceItem v-for="project in data.projects" :item="project" :key="project.id" />
    </template>

    <template #form>
      <ProfileExperienceForm ref="form" @close="closeForm" />
    </template>
  </ProfileSection>
</template>

<script setup>
import ProfileSection from '@/components/user/profile/ProfileSection'
import ProfileExperienceItem from '@/components/user/profile/ProfileExperienceItem'
import ProfileExperienceForm from '@/components/user/profile/forms/ProfileExperienceForm'

const props = defineProps({
  user: Object
})

const section = ref()
const form = ref()
const user = inject('user')
const data = computed(() => props.user ?? user.value)

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style scoped lang="scss">

</style>
