<template>
  <ProfileField name="Место работы">
    <ProfileFieldBody
      :accent="item.name"
      :additional="dates"
      :title="item.position"
      :description="item.description"
    />
  </ProfileField>
</template>

<script setup>
import ProfileField from '@/components/user/profile/ProfileField'
import ProfileFieldBody from '@/components/user/profile/ProfileFieldBody'

const props = defineProps({
  item: Object
})

const formatDate = date => new Date(date).toLocaleDateString('ru-RU', { month: 'short', year: 'numeric' })
const dates = computed(() => `с ${formatDate(props.item.date_from)} по ` + (props.item.date_to ? formatDate(props.item.date_to) : 'настоящее время'))
</script>

<style scoped lang="scss">

</style>
