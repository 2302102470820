<template>
  <ProfileToggleSection
    title="Стать специалистом"
    description="Став специалистом, ты сможешь откликаться на проекты"
    v-model:toggle="toggle"
    :can-toggle-off="!user?.is_specialist"
    :editable="editable"
    :editing="!!section?.editing"
    @edit="section?.edit()"
    hash-trigger="#specialist"
    v-slot="{ readonly }"
  >
    <ProfileSection ref="section" title="О специалисте" :custom="!readonly" @submit="submit" :pending="loading">
      <template #default>
        <ProfileField v-if="data.specialist_price" name="Почасовая ставка">{{ data.specialist_price }} ₽</ProfileField>
        <ProfileSkills v-if="data.specialist_skills?.length" :skills="data.specialist_skills" />
        <ProfileRequests v-if="data.specialist_requests?.length" :requests="data.specialist_requests" />
        <ProfileSpecialistVisibleForm />
      </template>

      <template #form>
        <ProfileSpecialistForm ref="form" @close="closeForm" />
      </template>
    </ProfileSection>
  </ProfileToggleSection>
</template>

<script setup>
const props = defineProps({
  user: Object
})

const editable = inject('profile.editable')

const section = ref()
const form = ref()
const user = inject('user')
const data = computed(() => props.user ?? user.value)

const toggle = ref(false)
watchImmediate(() => user.value?.is_specialist, value => toggle.value = !!value)

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style lang="scss">

</style>
