<template>
  <input type="checkbox" :id="id" v-model="value" />
  <label class="switcher" :class="{ 'switcher--not-clickable': notClickable }" :for="id" v-bind="$attrs" />
</template>

<script setup>
defineOptions({ inheritAttrs: false })

const props = defineProps({
  modelValue: Boolean,
  notClickable: Boolean
})
const emit = defineEmits(['update:modelValue'])

const id = getCurrentInstance().uid
const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<style scoped lang="scss">
.switcher {
  display: block;
  position: relative;
  width: 40px;
  height: 24px;
  cursor: pointer;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    opacity: .2;
    border-radius: 20px;
    transition: .2s opacity;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(48, 101, 0, 0.2);
    background: var(--color-white);
    transition: .2s transform;
  }

  &--not-clickable {
    pointer-events: none;
  }
}

input {
  display: none;

  &:checked + .switcher {
    &::before {
      opacity: 1;
    }
    &::after {
      transform: translateX(16px);
    }
  }
}
</style>
