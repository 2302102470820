<template>
  <main>
    <div class="horiz-container">
      <FancyBreadcrumbs v-if="!isOwn && (isMentorProfile || isSpecialistProfile)" :links="[
        { name: isMentorProfile ? 'Менторы' : 'Специалисты', to: { name: isMentorProfile ? 'mentor-list' : 'specialist-list' } },
        { name: fullName, to: { name: isMentorProfile ? 'mentor-profile' : 'specialist-profile', params: { id: profile.id } } }
      ]" />
      <div class="content" v-if="profile">
        <HybridRenderer v-slot="{ server }">
          <div class="profile default-grid">
            <div class="profile__sidebar">
              <div class="sidebar-info-wrapper">
                <UploadAvatar :user="profile" :server="server" />
                <ProfileMain :user="profile" />
              </div>
              <div class="profile__actions" v-if="!isOwn">
                <BaseButton v-if="isMentorProfile && !user?.is_mentor" class="profile__book-btn" color-primary stretch :to="{ name: 'mentor-book', params: { id: profile.id } }">Забронировать занятие</BaseButton>
                <div v-if="isSpecialistProfile" class="profile__contacts">
                  <div class="profile__contacts__header">
                    <h3 class="profile__contacts__title">Контакты</h3>
                    <p v-if="profile.contacts" class="profile__contacts__desc">{{ profile.first_name }} предоставил вам свои контакты</p>
                  </div>
                  <template v-if="!profile.contacts">
                    <SpecialistRequestButton
                      @click="onClick"
                      :specialist="profile"
                      stretch
                      @update:contacts="profile.contacts = $event"
                      @update:status="profile.status_owner_spec = $event"
                      compact
                    />
                   <FeedbackModal :modal-data="feedbackModalData" />
                    <div v-if="profile.available_contacts" class="profile__contacts__available">
                      <span>Доступные соц. сети:</span>
                      <ContactIcons class="profile__contacts__list" :keys="profile.available_contacts" />
                    </div>
                  </template>
                  <ProfileContacts v-else :contacts="profile.contacts" grow />
                </div>
              </div>
            </div>
            <div class="profile__main">
              <ProfileMain :user="profile" />
              <ProfileTabs :tabs="tabs" :server="server">
                <template #general><ProfileGeneral :user="profile" /></template>
                <template #mentor><ProfileMentor :user="profile" /></template>
                <template #specialist><ProfileSpecialist :user="profile" /></template>
                <template #experience><ProfileExperience :user="profile" /></template>
                <template #education><ProfileEducation :user="profile" /></template>
                <template #events><ProfileEvents :user="profile" /></template>
                <template #achievements><ProfileAchievements :is-mentor="isMentorProfile" :user="profile" /></template>
              </ProfileTabs>
            </div>
          </div>
        </HybridRenderer>
      </div>
    </div>
  </main>
</template>

<script setup>
import { getProfile } from '~/api/user'
import { getAllMyProjects } from '~/api/projects'
import useForm from '~/composables/useForm'
import { feedback } from '~/api/feedback'

const router = useRouter()
const route = useRoute()
const user = inject('user')

onMounted(() => {
  (function (w, d, s, o) {
    var j = d.createElement(s);
    j.async = true;
    j.src = '//script.marquiz.ru/v2.js';
    j.onload = function () {
      if (document.readyState !== 'loading') Marquiz.init(o);
      else document.addEventListener("DOMContentLoaded", function () {
        Marquiz.init(o);
      });
    };
    d.head.insertBefore(j, d.head.firstElementChild);
  })(window, document, 'script', {
        host: '//quiz.marquiz.ru',
        region: 'eu',
        id: '65ba494ab26e910026a413d9',
        autoOpen: false,
        autoOpenFreq: 'once',
        openOnExit: false,
        disableOnMobile: false
      }
  );
})

const callForm = () => {
  Marquiz.showModal('65ba494ab26e910026a413d9')
}

const { isMobile } = useBreakpoints()

const { data: profile } = await useAsyncData(async () => getProfile(route.params.id), { watch: [user] }) // TODO: Fix twice fetching of own profile after changes

const isOwn = computed(() => user.value && (profile.value?.user_id ?? profile.value?.id) === user.value.id)
const isMentorProfile = computed(() => route.name === 'mentor-profile' && !!profile.value?.is_mentor)
const isSpecialistProfile = computed(() => route.name === 'specialist-profile' && !!profile.value?.is_specialist)
provide('profile.editable', isOwn)

if (!profile.value) throw createNotFoundError()
if (!isOwn.value
    && (
        (route.name === 'mentor-profile' && !profile.value.is_mentor)
        || (route.name === 'specialist-profile' && !profile.value.is_specialist)
    )
) throw createNotFoundError()

const invitableProjects = useLazyAsyncData(async () => user.value && isSpecialistProfile.value && !profile.value.contacts ? await getAllMyProjects() : [], { default: () => null, watch: [user] })
provide('invitableProjects', invitableProjects)

const tabs = [
  { id: 'general', name: 'Общая информация' },
  { id: 'mentor', name: 'О менторе', shown: computed(() => isOwn.value || isMentorProfile.value) },
  { id: 'specialist', name: 'О специалисте', shown: computed(() => isOwn.value || isSpecialistProfile.value) },
  { id: 'experience', name: 'Работа и проекты', shown: computed(() => isOwn.value || profile.value.projects.length) },
  { id: 'education', name: 'Образование', shown: computed(() => isOwn.value || profile.value.educations.length) },
  { id: 'events', name: 'Профильные форумы и мероприятия', shown: computed(() => isOwn.value || profile.value.events.length) },
  { id: 'achievements', name: 'Достижения' }
]

const fullName = computed(() => (data => `${data.first_name} ${data.last_name}`)(profile.value.personal ?? profile.value))

useHead({
  title: profile.value.full_name + (isMentorProfile.value ? ' — ментор на Mentoring.digital' : '') + (isSpecialistProfile.value ? ' — специалист на Mentoring.digital' : ''),
  titleTemplate: '',
})

const feedbackModalData = ref({
  type: 'specialist',
  isShow: false,
})

function onClick() {
  return feedbackModalData.value.isShow = true
}
</script>

<style scoped lang="scss">
:deep(.profile-main) {
  @media (max-width: 920px) {
    display: none;
  }
}

.sidebar-info-wrapper {
  gap: 16px;
  align-items: flex-start;
  @media (max-width: 920px) {
    display: flex;
  }
  :deep(.profile-main) {
    display: none;
    flex: 1;
    @media (max-width: 920px) {
      display: block;
    }
  }
}

.profile {
  &__actions {
    margin-top: 24px;
  }
  &__actions > *:not(:first-child) {
    margin-top: 24px;
    border-top: 1px solid var(--color-separator);
    padding-top: 24px;
  }
  &__contacts {
    &__header {
      margin-bottom: 16px;
    }
    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 136%;
      color: var(--color-elements-primary);
    }
    &__desc {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: var(--color-elements-secondary);
      margin-top: 4px;
    }
    &__available {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--color-elements-secondary);
    }
    &__list {
      gap: 8px !important;
      color: var(--color-primary);
      opacity: .6;
    }
  }
}
</style>
