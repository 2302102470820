<template>
  <ProfileToggleSection
    title="Стать ментором"
    description="Став ментором, ты сможешь преподавать на платформе"
    v-model:toggle="toggle"
    :can-toggle-off="!user?.is_mentor"
    :editable="editable"
    :editing="!!section?.editing"
    @edit="section?.edit()"
    hash-trigger="#mentor"
    v-slot="{ readonly }"
  >
    <ProfileSection ref="section" title="О менторе" :custom="!readonly" @submit="submit" :pending="loading">
      <template #default>
        <ProfileField v-if="mentorPrice" name="Цена за занятие">{{ mentorPrice }} ₽</ProfileField>
        <ProfileSkills v-if="data.mentor_skills?.length" :skills="data.mentor_skills" />
        <ProfileRequests v-if="data.mentor_requests?.length" :requests="data.mentor_requests" />
        <ProfileMentorVisibleForm />
      </template>

      <template #form>
        <ProfileMentorForm ref="form" @close="closeForm" />
      </template>
    </ProfileSection>
  </ProfileToggleSection>
</template>

<script setup>
const props = defineProps({
  user: Object
})

const editable = inject('profile.editable')

const section = ref()
const form = ref()
const user = inject('user')
const data = computed(() => props.user ?? user.value)

const toggle = ref(false)
watchImmediate(() => user.value?.is_mentor, value => toggle.value = !!value)

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)

const mentorPrice = computed(() => data.value.mentor_price_with_commission ?? data.value.mentor_price ?? data.value.price)
</script>

<style lang="scss">

</style>
