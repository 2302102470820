<template>
  <ProfileEventsInput
    v-model="form.events"
    :errors="errors"
  />
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeEducationAdditionalInfo } from '@/api/user'
import cloneDeep from 'lodash.clonedeep'
import ProfileEventsInput from '@/components/user/profile/inputs/ProfileEventsInput'

const emit = defineEmits(['close'])

const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  form,
  errors,
  submit,
  loading
} = useForm(storeEducationAdditionalInfo, onSuccess)

async function onSuccess () {
  emit('close')
  await updateUserInfo()
}
defineExpose({ loading, submit })

watch(user, value => {
  form.value = {
    events: cloneDeep(value?.events) ?? []
  }
}, { immediate: true })
</script>

<style scoped lang="scss">

</style>
