<template>
  <ProfileSection ref="section" title="Профильные форумы и мероприятия" @submit="submit" :pending="loading" class="flex-mobile-separator">
    <template #default>
      <ProfileEventsItem v-for="event in data.events" :item="event" :key="event.id" />
    </template>

    <template #form>
      <ProfileEventsForm ref="form" @close="closeForm" />
    </template>
  </ProfileSection>
</template>

<script setup>
import ProfileSection from '@/components/user/profile/ProfileSection'
import ProfileEventsForm from '@/components/user/profile/forms/ProfileEventsForm'
import ProfileEventsItem from '@/components/user/profile/ProfileEventsItem'

const props = defineProps({
  user: Object
})

const section = ref()
const form = ref()
const user = inject('user')
const data = computed(() => props.user ?? user.value)

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style scoped lang="scss">

</style>
