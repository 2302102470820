<template>
  <Tabs v-if="isMobile || desktop" fluid :scrollable="isMobile" :tabs="tabs" v-model="tab" class="tabs" />
  <div v-if="isMobile || desktop" class="tab-content">
    <slot :name="selected?.id" />
  </div>
  <slot v-else v-for="tab in tabs" :key="tab.id" :name="tab.id" />
</template>

<script setup>
const props = defineProps({
  desktop: Boolean,
  tabs: Array,
  server: Boolean
})

const router = useRouter()
const route = useRoute()

const tabs = computed(() => props.tabs.filter(t => !t.shown || t.shown.value))

const tab = ref(0)
watch(() => route.hash, h => {
  const i = tabs.value.findIndex(t => t.id === h.slice(1))
  if (i !== -1) tab.value = i
}, { immediate: true })

const selected = computed(() => tabs.value[tab.value])
watch(selected, t => router.replace({ hash: `#${t.id}` }))

const { isMobile } = useBreakpoints(props)
</script>

<style scoped lang="scss">
.tabs {
  margin: 24px 0;
  border-bottom: 1px solid var(--color-separator);
  background: var(--color-bg);
  @media (max-width: 920px) {
    margin: 24px 0 24px -15px;
    width: calc(100% + 30px) !important;
    position: sticky !important;
    top: 59px;
    z-index: 20;
  }
}
</style>
