<template>
  <ProfileSection ref="section" title="Образование" @submit="submit" :pending="loading" class="flex-mobile-separator">
    <template #default>
      <ProfileEducationItem v-for="education in data.educations" :item="education" :key="education.id" />
    </template>

    <template #form>
      <ProfileEducationForm ref="form" @close="closeForm" />
    </template>
  </ProfileSection>
</template>

<script setup>
import ProfileSection from '@/components/user/profile/ProfileSection'
import ProfileEducationForm from '@/components/user/profile/forms/ProfileEducationForm'
import ProfileEducationItem from '@/components/user/profile/ProfileEducationItem'

const props = defineProps({
  user: Object
})

const section = ref()
const form = ref()
const user = inject('user')
const data = computed(() => props.user ?? user.value)

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style scoped lang="scss">

</style>
