<template>
  <AtomInput v-if="editable && user.is_specialist" type="checkbox"
             v-model="form.setting_visible_in_projects" name="Отображать мой профиль в списке специалистов" :error="errors.setting_visible_in_projects" />
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeSpecialistVisible } from '~/api/user'

const editable = inject('profile.editable')
const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const {
  form,
  submit,
  errors
} = useForm(storeSpecialistVisible, () => updateUserInfo(), () => form.value.setting_visible_in_projects = false)

watch(user, value => {
  form.value = {
    setting_visible_in_projects: value?.setting_visible_in_projects ?? false
  }
}, { immediate: true })

watchDebounced(() => form.value.setting_visible_in_projects, value => {
  if (value !== user.value.setting_visible_in_projects) submit()
}, { debounce: 800 })
</script>

<style scoped lang="scss">

</style>
