<template>
  <ProfileSection ref="section" title="Общая информация" @submit="submit" :pending="loading">
    <template #default>
      <ProfileField name="О себе" class="mentor-desc" :class="{ 'placeholder-hint': !data.general_about }">{{ data.general_about ?? 'Описание отсутствует' }}</ProfileField>
      <ProfileField v-if="data.languages?.length" name="Языки" class="chip-list">
        <Chip not-clickable v-for="lang in data.languages" :key="lang.id">{{ lang.title }}</Chip>
      </ProfileField>
      <ProfileField v-if="data.contacts" name="Контакты">
        <ProfileContacts :contacts="data.contacts" />
      </ProfileField>
    </template>

    <template #form>
      <ProfileGeneralForm ref="form" @close="closeForm" />
    </template>
  </ProfileSection>
</template>

<script setup>
const props = defineProps({
  user: Object
})

const section = ref()
const form = ref()
const user = inject('user')
const data = computed(() => props.user ?? user.value)

const closeForm = () => { section.value.editing = false }
const submit = () => form.value?.submit()
const loading = computed(() => !!form.value?.loading)
</script>

<style lang="scss">
.mentor-desc {
  white-space: pre-line;
}

.mentor-requests {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__item {
    display: flex;
    gap: 24px;
    align-items: flex-start;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    img {
      width: 12px;
      margin-top: 5px;
    }
  }
}
</style>
